.DetailedView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}

.DetailedView h1 {
  font-size: calc(30px + (100 - 30) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  color: var(--text-main);
}

.DetailedContent {
  margin: 20px 0;
  width: 70vw;
  font-size: 1.2em;
}

.embeds td {
  width: 90vw;
  max-width: 500px;
  margin: auto
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.DetailedContent p {
  margin: 30px 0;
}

.ts-icons {
  padding: 5px;
  font-size: 20px;
}

.details {
  display: grid;
  grid-template-rows: auto auto;
}

.details .images {
  width: 80vw;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.images img {
  height: 150px;
  padding: 5px;
  max-width: 80vw;
}

.more-links ul {
  display: flex;
  list-style: none;
}

.more-links ul li {
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .DetailedView {
    grid-template-rows: auto 1fr;
    margin: 0 20px;
    align-items: flex-end;
    /* margin-bottom: 100px; */
  }

  .DetailedView h1 {
    font-size: 3em;
    margin-top: 50px;
  }

  .DetailedContent {
    width: 90vw;
    font-size: 1.1em;
  }

  .images {
    margin: auto;
  }
}