.AccomplishmentsView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}

.AccomplishmentsView h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}

.AccomplishmentsContent {
  font-size: 1.2em;
}

.AccomplishmentsContent .cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.AccomplishmentsContent .cards .card {
  width: 80vw;
  padding: 15px;
  margin: 20px 20px 20px 0px;
  transition: 0.2s ease;
  overflow: hidden;
  display: grid;
  align-items: center;
  grid-template-columns: 374px 1fr;
  /* justify-content: space-between; */
}

.embed {
  max-width: 374px;
  width: 100%;
  height: 100%;
}

.AccomplishmentsContent .cards .card img {
  max-width: 374px;
  width: 100%;
}

.AccomplishmentsContent .cards .card .card-info h3 {
  color: var(--text-main);
  margin: 15px 0;
}

.AccomplishmentsContent .cards .card .card-info {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 25px;
}

/*
.AccomplishmentsContent .cards .card .card-links {
  transform: translateY(100px);
}
.AccomplishmentsContent .cards .card:hover .card-links {
  font-size: 15px;
  transform: translateY(20px);
}
.AccomplishmentsContent .cards .card:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  outline: 0.1px solid var(--text-main);
  outline-offset: -1px;
} */
@media only screen and (max-width: 800px) {
  .AccomplishmentsView {
    grid-template-rows: 100px 3fr;
    margin: 0 20px;
    align-items: flex-end;
  }

  .AccomplishmentsView h1 {
    font-size: 2em;
  }

  .AccomplishmentsContent {
    width: 90vw;
    font-size: 1.1em;
  }

  .AccomplishmentsContent .cards {
    justify-content: center;
  }

  .AccomplishmentsContent .cards .card {
    grid-template-columns: none;
    grid-template-rows: auto auto;
    height: auto;
  }

  .AccomplishmentsContent .cards .card img {
    width: 80%;
    margin: auto;
  }
}