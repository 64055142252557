.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: var(--background);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: var(--background);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--text-secondary);
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--text-main);
}

nav {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--text-secondary) !important;
  background-color: var(--background);
  z-index: 999999;
}

nav .active {
  display: none;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0px 20px;
  transition: 0.3s ease;
  font-size: 0.6em;
  width: 100px;
  text-decoration: none;
  cursor: pointer;
}

.nav-item:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-1px);
  color: var(--text-main) !important;
}

.content {
  height: auto;
  width: 100%;
  overflow-x: hidden;
}

@media only screen and (max-width: 800px) {
  nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.12);
    position: fixed;
    bottom: 0;
    z-index: 999999;
  }

  .content>div {
    margin-bottom: 100px;
  }

  .nav-item {
    margin: 0;
  }

  .nav-item:hover {
    box-shadow: none;
    transform: none;
  }
}

#mainApp {
  overflow: hidden;
}

@keyframes leave {
  5% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}