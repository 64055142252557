.CertificatesView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}
.CertificatesView h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}
.CertificatesContent {
  margin: 20px 0;
  font-size: 1.2em;
}
.CertificatesContent .cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.CertificatesContent .cards .card {
  max-width: 350px;
  width: 80vw;
  padding: 15px;
  margin: 20px 20px 20px 0px;
  transition: 0.2s ease;
  overflow: hidden;
  border: 1px red;
}
.CertificatesContent .cards .card p {
  perspective: 50;
}

@media only screen and (max-width: 800px) {
  .CertificatesView {
    grid-template-rows: 100px 3fr;
    margin: 0 20px;
    align-items: flex-end;
  }
  .CertificatesContent {
    width: 90vw;
    font-size: 1.1em;
  }
  .CertificatesContent .cards {
    justify-content: center;
  }
}
