.aboutView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}

.aboutView h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}

.aboutContent {
  margin: 20px 0;
  width: 70vw;
  font-size: 1.2em;
}

.aboutContent p {
  margin: 30px 0;
}

.aboutContent ul li {
  margin: 10px 0;
}

@media only screen and (max-width: 800px) {
  .aboutView {
    grid-template-rows: 100px 3fr;
    margin: 0 20px;
    align-items: flex-end;
  }

  .aboutContent {
    width: 90vw;
    font-size: 1.1em;
  }
}