@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
* {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  font-family: "Open Sans", sans-serif;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

:root {
  --text-main: #ffff;
  --text-secondary: #d1d1d1;
  --background: #0f0f0f;
  --text-main: #5e5e5e;
  --text-secondary: #6f6f70;
  --background: #fff;
}

.anchors {
  text-decoration: underline;
}

a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

html,
body {
  background: #fff;
  background: var(--background);
}
.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: var(--background);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: var(--background);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--text-secondary);
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--text-main);
}

nav {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--text-secondary) !important;
  background-color: var(--background);
  z-index: 999999;
}

nav .active {
  display: none;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0px 20px;
  transition: 0.3s ease;
  font-size: 0.6em;
  width: 100px;
  text-decoration: none;
  cursor: pointer;
}

.nav-item:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-1px);
  color: var(--text-main) !important;
}

.content {
  height: auto;
  width: 100%;
  overflow-x: hidden;
}

@media only screen and (max-width: 800px) {
  nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.12);
    position: fixed;
    bottom: 0;
    z-index: 999999;
  }

  .content>div {
    margin-bottom: 100px;
  }

  .nav-item {
    margin: 0;
  }

  .nav-item:hover {
    box-shadow: none;
    transform: none;
  }
}

#mainApp {
  overflow: hidden;
}

@-webkit-keyframes leave {
  5% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes leave {
  5% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.aboutView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}

.aboutView h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}

.aboutContent {
  margin: 20px 0;
  width: 70vw;
  font-size: 1.2em;
}

.aboutContent p {
  margin: 30px 0;
}

.aboutContent ul li {
  margin: 10px 0;
}

@media only screen and (max-width: 800px) {
  .aboutView {
    grid-template-rows: 100px 3fr;
    margin: 0 20px;
    align-items: flex-end;
  }

  .aboutContent {
    width: 90vw;
    font-size: 1.1em;
  }
}
.view {
  display: grid;
  grid-template-rows: 1fr 0.4fr;
  height: 100%;
}

.top {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.top .face-img {
  width: 45%;
  margin: auto;
  transition: transform 0.2s;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 100%;
}

.top .info {
  color: var(--text-secondary);
  text-align: left;
  font-size: 2.5em;
  width: 80%;
}

.top .info strong:hover {
  border-bottom: 1px solid var(--text-secondary);
}

.img-resume {
  color: var(--text-main);
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  text-align: center;
}



.bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.bottom .bottom-links {
  font-size: 1.8em;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

.bottom .bottom-links:hover {
  border-bottom: 1px solid var(--text-secondary);
}

@media only screen and (max-width: 800px) {
  .view {
    grid-template-rows: 1fr 0.5fr;
  }

  .switch-icons {
    padding: 5px;
    font-size: 25px;
    color: var(--text-secondary);
  }

  .top {
    grid-template-columns: none;
    grid-template-rows: auto;
    align-items: center;
  }

  .top .face-img {
    width: 35%;
  }

  .top .info {
    width: 80%;
    margin: auto;
    margin-top: 0;
    font-size: 1.5em;
  }

  .bottom .bottom-links {
    font-size: 1.5em;
  }
}

.indicator {
  position: absolute;
  top: 5px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
}

.dot {
  background: var(--text-main);
  width: 5px;
  border-radius: 100%;
  margin: 5px;
  height: 5px;
}

.face-img:active {
  transform: translateY(-5px);
  -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.ProjectsView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}
.ProjectsView h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}
.ProjectsContent {
  margin: 20px 0;
  font-size: 1.2em;
}
.ProjectsContent .cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.ProjectsContent .cards .card {
  max-width: 300px;
  width: 80vw;
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px;
  margin: 20px 20px 20px 1px;
  transition: 0.2s ease;
  overflow: hidden;
}
.ProjectsContent .cards .card .card-info {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* height: 130px; */
}
.ProjectsContent .cards .card .card-info h3 {
  color: var(--text-main);
  margin-bottom: 10px;
  font-size: 1.5em;
}
.ProjectsContent .cards .card .card-info .tags {
  display: flex;
  margin-top: 10px;
}
.ProjectsContent .cards .card .card-info .tags p {
  text-align: center;
  font-size: 12px;
  padding: 5px;
  background-color: var(--text-secondary);
  color: var(--background);
  margin: 5px;
  margin-left: 0;
  /* border-radius: 50%; */
}
.ProjectsContent .cards .card .card-links {
  transform: translateY(100px);
  margin: 10px 0;
}
.ProjectsContent .cards .card:hover .card-links {
  /* font-size: 15px; */
  transform: translateY(0px);
}
.ProjectsContent .cards .card:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* transform: translateY(2px); */
  outline: 0.1px solid var(--text-main);
  outline-offset: -1px;
}
@media only screen and (max-width: 800px) {
  .ProjectsView {
    grid-template-rows: 100px 3fr;
    margin: 0 20px;
    align-items: flex-end;
  }
  .ProjectsContent {
    width: 90vw;
    font-size: 1.1em;
  }
  .ProjectsContent .cards {
    justify-content: center;
  }
  .ProjectsContent .cards .card {
    margin: 20px;
  }
  .ProjectsContent .cards .card .card-links {
    transform: translateY(0px);
  }
}

.CertificatesView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}
.CertificatesView h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}
.CertificatesContent {
  margin: 20px 0;
  font-size: 1.2em;
}
.CertificatesContent .cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.CertificatesContent .cards .card {
  max-width: 350px;
  width: 80vw;
  padding: 15px;
  margin: 20px 20px 20px 0px;
  transition: 0.2s ease;
  overflow: hidden;
  border: 1px red;
}
.CertificatesContent .cards .card p {
  perspective: 50;
}

@media only screen and (max-width: 800px) {
  .CertificatesView {
    grid-template-rows: 100px 3fr;
    margin: 0 20px;
    align-items: flex-end;
  }
  .CertificatesContent {
    width: 90vw;
    font-size: 1.1em;
  }
  .CertificatesContent .cards {
    justify-content: center;
  }
}

.AccomplishmentsView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}

.AccomplishmentsView h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}

.AccomplishmentsContent {
  font-size: 1.2em;
}

.AccomplishmentsContent .cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.AccomplishmentsContent .cards .card {
  width: 80vw;
  padding: 15px;
  margin: 20px 20px 20px 0px;
  transition: 0.2s ease;
  overflow: hidden;
  display: grid;
  align-items: center;
  grid-template-columns: 374px 1fr;
  /* justify-content: space-between; */
}

.embed {
  max-width: 374px;
  width: 100%;
  height: 100%;
}

.AccomplishmentsContent .cards .card img {
  max-width: 374px;
  width: 100%;
}

.AccomplishmentsContent .cards .card .card-info h3 {
  color: var(--text-main);
  margin: 15px 0;
}

.AccomplishmentsContent .cards .card .card-info {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 25px;
}

/*
.AccomplishmentsContent .cards .card .card-links {
  transform: translateY(100px);
}
.AccomplishmentsContent .cards .card:hover .card-links {
  font-size: 15px;
  transform: translateY(20px);
}
.AccomplishmentsContent .cards .card:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  outline: 0.1px solid var(--text-main);
  outline-offset: -1px;
} */
@media only screen and (max-width: 800px) {
  .AccomplishmentsView {
    grid-template-rows: 100px 3fr;
    margin: 0 20px;
    align-items: flex-end;
  }

  .AccomplishmentsView h1 {
    font-size: 2em;
  }

  .AccomplishmentsContent {
    width: 90vw;
    font-size: 1.1em;
  }

  .AccomplishmentsContent .cards {
    justify-content: center;
  }

  .AccomplishmentsContent .cards .card {
    grid-template-columns: none;
    grid-template-rows: auto auto;
    height: auto;
  }

  .AccomplishmentsContent .cards .card img {
    width: 80%;
    margin: auto;
  }
}
.banner-big {
  position: absolute;
  width: 50px;
  bottom: 71px;
  right: 35px;
  color: var(--text-secondary) !important;
  display: flex;
  flex-direction: column;
}
.banner-small {
  display: none;
}
.banner-big .icons {
  margin: 5px auto;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.01));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.01));
}
.banner-big .icons:hover {
  color: var(--text-main) !important;
}
.banner-big .icons::before {
  transition: 0.2s ease;
  color: var(--text-main);
  opacity: 0;
  top: 25%;
  right: 30px;
  position: absolute;
}
.banner-big .icons:hover::before {
  right: 50px;
  opacity: 1;
}
#github::before {
  content: "SuyashSonawane";
}
#mail::before {
  content: "suyashsonawane007";
}
#linkedin::before {
  content: "in/suyashsonawane";
}
#kaggel::before {
  content: "suyashsonawane";
}
#twitter::before {
  content: "SuyashYSonawane";
}
@media only screen and (max-width: 800px) {
  .banner-big {
    display: none;
  }
  .banner-small {
    position: fixed;
    color: var(--background);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 15vw;
    height: 45px;
    right: 0;
    bottom: 71px;
    margin: 10px 0vw;
    background-color: var(--text-main);
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
    transition: all 0.2s ease;
  }
  .banner-small .icons::before {
    display: none;
  }
  .banner-active {
    width: 100vw;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.DetailedView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}

.DetailedView h1 {
  font-size: calc(30px + (100 - 30) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  color: var(--text-main);
}

.DetailedContent {
  margin: 20px 0;
  width: 70vw;
  font-size: 1.2em;
}

.embeds td {
  width: 90vw;
  max-width: 500px;
  margin: auto
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.DetailedContent p {
  margin: 30px 0;
}

.ts-icons {
  padding: 5px;
  font-size: 20px;
}

.details {
  display: grid;
  grid-template-rows: auto auto;
}

.details .images {
  width: 80vw;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.images img {
  height: 150px;
  padding: 5px;
  max-width: 80vw;
}

.more-links ul {
  display: flex;
  list-style: none;
}

.more-links ul li {
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .DetailedView {
    grid-template-rows: auto 1fr;
    margin: 0 20px;
    align-items: flex-end;
    /* margin-bottom: 100px; */
  }

  .DetailedView h1 {
    font-size: 3em;
    margin-top: 50px;
  }

  .DetailedContent {
    width: 90vw;
    font-size: 1.1em;
  }

  .images {
    margin: auto;
  }
}
.switch {
    position: fixed;
    right: 5px;
    top: 5px;
}

.switch-icons {
    padding: 15px;
    font-size: 45px;
    color: var(--text-secondary);
    transition: all 0.2s ease;
    cursor: pointer;
}

.switch-icons:hover {
    transform: rotate(360deg);
}

@media only screen and (max-width: 800px) {

    .switch-icons {
        padding: 5px;
        font-size: 25px;
        color: var(--text-secondary);
    }

}
