.ProjectsView {
  color: var(--text-secondary);
  margin: 5px 50px;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 150px 1.9fr;
  align-items: center;
}
.ProjectsView h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}
.ProjectsContent {
  margin: 20px 0;
  font-size: 1.2em;
}
.ProjectsContent .cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.ProjectsContent .cards .card {
  max-width: 300px;
  width: 80vw;
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px;
  margin: 20px 20px 20px 1px;
  transition: 0.2s ease;
  overflow: hidden;
}
.ProjectsContent .cards .card .card-info {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* height: 130px; */
}
.ProjectsContent .cards .card .card-info h3 {
  color: var(--text-main);
  margin-bottom: 10px;
  font-size: 1.5em;
}
.ProjectsContent .cards .card .card-info .tags {
  display: flex;
  margin-top: 10px;
}
.ProjectsContent .cards .card .card-info .tags p {
  text-align: center;
  font-size: 12px;
  padding: 5px;
  background-color: var(--text-secondary);
  color: var(--background);
  margin: 5px;
  margin-left: 0;
  /* border-radius: 50%; */
}
.ProjectsContent .cards .card .card-links {
  transform: translateY(100px);
  margin: 10px 0;
}
.ProjectsContent .cards .card:hover .card-links {
  /* font-size: 15px; */
  transform: translateY(0px);
}
.ProjectsContent .cards .card:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* transform: translateY(2px); */
  outline: 0.1px solid var(--text-main);
  outline-offset: -1px;
}
@media only screen and (max-width: 800px) {
  .ProjectsView {
    grid-template-rows: 100px 3fr;
    margin: 0 20px;
    align-items: flex-end;
  }
  .ProjectsContent {
    width: 90vw;
    font-size: 1.1em;
  }
  .ProjectsContent .cards {
    justify-content: center;
  }
  .ProjectsContent .cards .card {
    margin: 20px;
  }
  .ProjectsContent .cards .card .card-links {
    transform: translateY(0px);
  }
}
