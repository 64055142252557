.switch {
    position: fixed;
    right: 5px;
    top: 5px;
}

.switch-icons {
    padding: 15px;
    font-size: 45px;
    color: var(--text-secondary);
    transition: all 0.2s ease;
    cursor: pointer;
}

.switch-icons:hover {
    transform: rotate(360deg);
}

@media only screen and (max-width: 800px) {

    .switch-icons {
        padding: 5px;
        font-size: 25px;
        color: var(--text-secondary);
    }

}