.banner-big {
  position: absolute;
  width: 50px;
  bottom: 71px;
  right: 35px;
  color: var(--text-secondary) !important;
  display: flex;
  flex-direction: column;
}
.banner-small {
  display: none;
}
.banner-big .icons {
  margin: 5px auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.01));
}
.banner-big .icons:hover {
  color: var(--text-main) !important;
}
.banner-big .icons::before {
  transition: 0.2s ease;
  color: var(--text-main);
  opacity: 0;
  top: 25%;
  right: 30px;
  position: absolute;
}
.banner-big .icons:hover::before {
  right: 50px;
  opacity: 1;
}
#github::before {
  content: "SuyashSonawane";
}
#mail::before {
  content: "suyashsonawane007";
}
#linkedin::before {
  content: "in/suyashsonawane";
}
#kaggel::before {
  content: "suyashsonawane";
}
#twitter::before {
  content: "SuyashYSonawane";
}
@media only screen and (max-width: 800px) {
  .banner-big {
    display: none;
  }
  .banner-small {
    position: fixed;
    color: var(--background);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 15vw;
    height: 45px;
    right: 0;
    bottom: 71px;
    margin: 10px 0vw;
    background-color: var(--text-main);
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
    transition: all 0.2s ease;
  }
  .banner-small .icons::before {
    display: none;
  }
  .banner-active {
    width: 100vw;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
