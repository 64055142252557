@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  font-family: "Open Sans", sans-serif;
  user-select: none;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

:root {
  --text-main: #ffff;
  --text-secondary: #d1d1d1;
  --background: #0f0f0f;
  --text-main: #5e5e5e;
  --text-secondary: #6f6f70;
  --background: #fff;
}

.anchors {
  text-decoration: underline;
}

a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

html,
body {
  background: var(--background);
}