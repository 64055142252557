.view {
  display: grid;
  grid-template-rows: 1fr 0.4fr;
  height: 100%;
}

.top {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.top .face-img {
  width: 45%;
  margin: auto;
  transition: transform 0.2s;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 100%;
}

.top .info {
  color: var(--text-secondary);
  text-align: left;
  font-size: 2.5em;
  width: 80%;
}

.top .info strong:hover {
  border-bottom: 1px solid var(--text-secondary);
}

.img-resume {
  color: var(--text-main);
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  text-align: center;
}



.bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.bottom .bottom-links {
  font-size: 1.8em;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

.bottom .bottom-links:hover {
  border-bottom: 1px solid var(--text-secondary);
}

@media only screen and (max-width: 800px) {
  .view {
    grid-template-rows: 1fr 0.5fr;
  }

  .switch-icons {
    padding: 5px;
    font-size: 25px;
    color: var(--text-secondary);
  }

  .top {
    grid-template-columns: none;
    grid-template-rows: auto;
    align-items: center;
  }

  .top .face-img {
    width: 35%;
  }

  .top .info {
    width: 80%;
    margin: auto;
    margin-top: 0;
    font-size: 1.5em;
  }

  .bottom .bottom-links {
    font-size: 1.5em;
  }
}

.indicator {
  position: absolute;
  top: 5px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
}

.dot {
  background: var(--text-main);
  width: 5px;
  border-radius: 100%;
  margin: 5px;
  height: 5px;
}

.face-img:active {
  transform: translateY(-5px);
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}